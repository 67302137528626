interface Language {
  locale: string;
  name: string;
  shopwareId: string;
  active: boolean;
}

export function useLanguageSwitcher() {
  const { locales, locale: currentLocale, defaultLocale } = useI18n();
  const { languages: shopwareLanguages } = useInternationalization();
  const switchLocalePath = useSwitchLocalePath();
  const { apiClient } = useShopwareContext();
  const { resolvePath } = useNavigationSearch();
  const route = useRoute();

  const languages = computed(() => {
    const result = [] as Language[];
    for (const locale of locales.value) {
      const lang = shopwareLanguages.value.find(
        (x) => x.translationCode?.code === locale.code,
      );

      assert(lang, `Language ${locale.code} not found in Shopware Languages`);

      result.push({
        locale: locale.code,
        name: lang.name,
        shopwareId: lang.id,
        active: currentLocale.value === locale.code,
      });
    }

    return result;
  });

  async function switchLanguage(locale: string) {
    const language = languages.value.find((x) => x.locale === locale);
    assert(language, `Language ${locale} not found in Languages`);

    if (language.active) return window.location.reload();

    if (!history.state?.routeName || !history.state?.foreignKey) {
      const routePath = route.path
        .replace(`${currentLocale.value}`, "")
        .replace("//", "/");

      const seoData = await resolvePath(routePath);

      if (seoData) {
        history.replaceState(
          {
            routeName: seoData.routeName,
            foreignKey: seoData.foreignKey,
          },
          "",
        );
      }
    }

    if (history.state?.routeName && history.state?.foreignKey) {
      const response = await apiClient.invoke("readSeoUrl post /seo-url", {
        body: {
          filter: [
            {
              type: "equals",
              field: "routeName",
              value: history.state.routeName,
            },
            {
              type: "equals",
              field: "foreignKey",
              value: history.state.foreignKey,
            },
          ],
        },
        headers: { "sw-language-id": language.shopwareId },
      });

      if (response.data.elements?.[0]?.seoPathInfo) {
        const target =
          `${locale === defaultLocale ? "" : "/" + locale}/${response.data.elements[0].seoPathInfo}` +
          window.location.search +
          window.location.hash;

        window.location.href = target;
      }
    } else {
      window.location.href = switchLocalePath(locale);
    }
  }

  return {
    locale: currentLocale,
    languages,
    switchLanguage,
  };
}
